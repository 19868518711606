import {
  SelectOption,
  BlogCategoryOption,
  FormsProps,
  CountryZoomLevels
} from './types'
import { NewsletterProps, HeaderProps } from '../../components'

export const MAP_API_KEY =
  process.env.MAPBOX_API_KEY ||
  'pk.eyJ1IjoicGx1c2ZpdG5lc3MiLCJhIjoiY2txNXVlbWo4MHR5azJwb2h1Z3N5bTdxMCJ9.RGW_SHvAZmYjqgzWbAma5Q'
export const baseMap = 'mapbox'
export const mapId = 'streets-v8'
export const defaultMapCenter: [number, number] = [135, -27] // Australia
export const defaultMapZoom: [number] = [3]
export const gymLocationMapZoom: [number] = [2.5]
export const gymLocationMapZoomMobile: [number] = [2.25]
export const maximumParagraphInShortContent: number = 900
export const siteZoomLevels: CountryZoomLevels = {
  nz: {
    desktop: [4],
    mobile: [4]
  },
  au: {
    desktop: [2.5],
    mobile: [2.25]
  },
  in: {
    desktop: [4.5],
    mobile: [4.25]
  },
  id: {
    desktop: [4.5],
    mobile: [3.25]
  },
  sg: {
    desktop: [4.5],
    mobile: [3.25]
  },
  other: {
    desktop: [2.5],
    mobile: [2.25]
  }
}
export const basedMapStyle = `mapbox://styles/${baseMap}/${mapId}`
export const SORT_PLACEHODLER = 'Sort by'
export const ITEM_PER_PAGE: number = 6
export const SORT_OPTIONS: Array<SelectOption> = [
  {
    label: 'Latest',
    value: '-created'
  },
  {
    label: 'Oldest',
    value: 'created'
  },
  {
    label: 'Neareast',
    value: 'distance'
  },
  {
    label: 'Size',
    value: '-size'
  }
]
export const GYMS_FILTER: Array<SelectOption> = [
  {
    label: 'All Gyms',
    value: ''
  },
  {
    label: 'Open',
    value: 'Open'
  },
  {
    label: 'Opening Soon',
    value: 'Opening Soon'
  }
]

export const YOU_FITNESS = {
  titleImageGallery: 'GYM PICS',
  learnMoreBtn: 'Learn More',
  readMoreBtn: 'Read More',
  readLessBtn: 'Read Less',
  descMainSectionMobileLeft: 'It’s time for a change',
  descMainSectionMobileRight: 'Lift Yourself Up',
  callNowBtn: 'Call Now',
  emailBtn: 'Email',
  instagramBtn: 'Instagram'
}

export const HEAR_ABOUT_REASONS: Array<SelectOption> = [
  { label: 'Bus advertising', value: 'Bus advertising' },
  { label: 'Community event', value: 'Community event' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Fitness expo', value: 'Fitness expo' },
  { label: 'Flyer', value: 'Flyer' },
  { label: 'Google', value: 'Google' },
  { label: 'Local sponsorship', value: 'Local sponsorship' },
  { label: 'Newspaper/Magazine', value: 'Newspaper/Magazine' },
  {
    label: 'Friend is a member of Plus Fitness',
    value: 'Friend is a member of Plus Fitness'
  },
  { label: 'Online advert', value: 'Online advert' },
  { label: 'Online directory', value: 'Online directory' },
  { label: 'Previously a member', value: 'Previously a member' },
  { label: 'Radio', value: 'Radio' },
  { label: 'Saw a Plus Fitness', value: 'Saw a Plus Fitness' },
  { label: 'TV', value: 'TV' },
  { label: 'V8 Supercars', value: 'V8 Supercars' },
  { label: 'Other', value: 'Other' }
]
export const STATE_OPTIONS: Array<SelectOption> = [
  { label: 'State', value: '' },
  { label: 'NSW', value: 'NSW' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' }
]
export const YESNO_OPTIONS: Array<SelectOption> = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' }
]
export const TIMEFRAME_OPTIONS: Array<SelectOption> = [
  { label: 'Timeframe for opening a business', value: '' },
  { label: 'Right Now', value: 'Right Now' },
  { label: '3 Months', value: '3 Months' },
  { label: '6 Months', value: '6 Months' },
  { label: '12+ Months', value: '12+ Months' }
]

export const newsletterProps: NewsletterProps = {
  newsletterTitle: `STAY INFORMED`,
  newsletterMsg: `Register for regular updates, blogs and Plus Fitness news`,
  subBtnText: `SUBSCRIBE TO THE NEWSLETTER`,
  successTitle: 'Thank you for subscribing to our newsletter',
  successMessage: '',
  errorTitle: 'Newsletter Error',
  errorMessage: ''
}

export const navHeaderProps: HeaderProps = {
  CTATitle: 'Find your local Plus Fitness',
  Titles: ['24/7 gym -', 'no lock in contracts'],
  buttonLabel: 'Search',
  buttonAction: '#',
  searchInputProps: {
    placeholder: 'Type your suburb or post code'
  }
}

export const GymJoinLink =
  'https://express.ffapaysmart.com.au//DDR/JoinOnline.aspx?AID=B564097C307FA1CF'

export const EMPTY_ARTICLE_MESSEGE =
  'There are no matching articles, Please try again with a different search'

export const ARTICLE_SORT_OPTIONS: Array<SelectOption> = [
  {
    label: 'Latest',
    value: '-date'
  },
  {
    label: 'Oldest',
    value: 'date'
  }
]

export const ALL_CATEGORY_FILTER: Array<BlogCategoryOption> = [
  {
    id: 0,
    name: 'All categories'
  }
]

export const generalItemsPerPage = 100

export const askPasswordFormProps: FormsProps = {
  slug: 'password',
  title: 'Please input your password to see the page',
  fields: [
    {
      help_text: 'Input your password',
      label: 'Password',
      name: 'password',
      required: true,
      type: 'password',
      value: ''
    }
  ],
  id: 1,
  formAction: '',
  submitText: 'Access'
}

export const BlocksShowPageTitle = [
  'content',
  'ContentWithCTA',
  'Heading',
  'Hero',
  'hero',
  'gym_hero',
  'BlogHeroSlide'
]

export const VideoPlayerImgAlt = 'Play video - Become a member'

export const FindAGymListTitle = 'FIND A GYM NEAR YOU'

export const DefaultAlt = 'Plus fitness'

export const gotoEnquireFormLabel = 'enquiry'

export const PAGE_TEMPLATE = {
  ORIGINAL: 'your_local',
  YOU_FITNESS: 'you_fitness'
}

export const COUNTRY_CODE = {
  INDIA: 'in',
  SINGAPORE: 'sg',
  AUSTRALIA: 'au',
  INDONESIA: 'id',
  NEWZEALAND: 'nz'
}

export const YOU_FITNESS_TEMPLATE = '2021'

export const RECAPTCHA_KEY: string =
  process.env.NEXT_PUBLIC_RECAPTCHA_KEY ||
  '6LdOTq0UAAAAAEURSOaIHQsqW2gGgNtr5Jn-lw7x'

export const SUBURBS_GROUP: { [key: string]: string } = {
  metro_suburbs: 'Metro',
  regional_suburbs: 'Regional',
  other_suburbs: 'Other'
}

export default {}
