import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const EnquiryFormRow = styled.div<{
  justify?: 'start' | 'center' | 'end'
}>`
  ${tw`flex flex-col lg:flex-row lg:justify-around lg:w-full`}

  ${({ justify }) => {
    switch (justify) {
      case 'start':
        return tw`lg:justify-start`
      case 'center':
        return tw`lg:justify-center`
      case 'end':
        return tw`lg:justify-end`
    }
  }}
`
export const EnquiryForm = styled.form<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`m-4 lg:flex lg:flex-wrap lg:justify-start`}

  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`lg:justify-center`}
    `}
`
